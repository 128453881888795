import './Crowther.scss';

import React from 'react';

import CrowtherMintDetails from '../../components/mint/crowther/CrowtherMintDetails';

const Crowther: React.FC<any> = () => {
	return (
		<div className="crowther-container">
			<div className="crowther" lang="en">
				<div className="crowther-title">RED BANDANNA SNEAKERS</div>

				<div className="crowther-mint-details-container">
					<CrowtherMintDetails />
				</div>
			</div>
		</div>
	);
};

export default Crowther;
