import './CrowtherMintDetails.scss';

import { BrowserView, MobileView } from 'react-device-detect';

import getCrowtherPageContent from '../../../drops/crowtherPageContent';
import { useState } from 'react';
import CrowtherMintButton from './CrowtherMintButton';

const CrowtherMintDetails: React.FC<any> = (props) => {
	const { getDescription, nftArtUrl, sketchfabUrl, attributes, perks } =
		getCrowtherPageContent();

	const [selectedPerkTab, setSelectedPerkTab] = useState('Physical');

	const getPerksContent = () => {
		const selectedPerks = perks[selectedPerkTab.toLowerCase()];
		return (
			<div className="crowther-perks-bullet-list">
				{selectedPerks.map((p: any, i: number) => (
					<div className="crowther-perks-bullet-item" key={i}>
						{p}
					</div>
				))}
			</div>
		);
	};

	const getAttributesContent = () => {
		return (
			<div className="crowther-mint-details-attributes">
				<div className="crowther-mint-details-attributes-line">
					<div className="crowther-mint-details-attributes-header">
						Attributes
					</div>
				</div>
				{attributes.map((attr: any, i: number) => (
					<div
						className="crowther-mint-details-attributes-line"
						key={i}
					>
						<div className="crowther-mint-details-attributes-text">
							{attr.key}
						</div>
						<div className="crowther-mint-details-attributes-text">
							{attr.value}
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<div>
			<div className="crowther-mint">
				<div className="crowther-mint-left">
					<div className="crowther-mint-nft">
						<video
							src={nftArtUrl}
							autoPlay
							muted
							loop
							playsInline
						/>
						<MobileView>
							{
								<CrowtherMintButton className="crowther-mint-details-button" />
							}
						</MobileView>
						<MobileView>{getDescription()}</MobileView>
						<div className="crowther-mint-nft-specs">
							<div className="crowther-perks-tab-container">
								<div
									className={`crowther-perks-tab ${
										selectedPerkTab === 'Physical'
											? 'crowther-perks-tab-selected'
											: 'crowther-perks-tab-unselected'
									}`}
									onClick={() =>
										setSelectedPerkTab('Physical')
									}
								>
									<p>Physical</p>
								</div>
								<div
									className={`crowther-perks-tab ${
										selectedPerkTab === 'Digital'
											? 'crowther-perks-tab-selected'
											: 'crowther-perks-tab-unselected'
									}`}
									onClick={() =>
										setSelectedPerkTab('Digital')
									}
								>
									<p>Digital</p>
								</div>
								<div
									className={`crowther-perks-tab ${
										selectedPerkTab === 'Experiential'
											? 'crowther-perks-tab-selected'
											: 'crowther-perks-tab-unselected'
									}`}
									onClick={() =>
										setSelectedPerkTab('Experiential')
									}
								>
									<p>Experiential</p>
								</div>
							</div>
							<div className="drops2-perks-content">
								{getPerksContent()}
							</div>
						</div>
					</div>
					<div className="crowther-mint-details">
						<BrowserView>{getDescription()}</BrowserView>
						<BrowserView>
							{
								<CrowtherMintButton
									className="crowther-mint-details-button"
									fullWidth
								/>
							}
						</BrowserView>
						{getAttributesContent()}
					</div>
				</div>
				<div className="crowther-long-description crowther-long-description-small">
					<h2 className="mb-4">Welles' Story</h2>
					<p>
						Welles Crowther was an equities trader and volunteer
						firefighter who gave his life to save at least 12 people
						during the 9/11 terrorist attacks in New York City.
					</p>
					<p>
						After graduating from Boston College in 1999, Welles
						began work on the 104th floor of the South Tower of the
						World Trade Center in NYC.
					</p>
					<p>
						On September 11, 2001 when the attacks began, Welles
						pulled out his red bandanna, which he had kept in his
						back pocket since he was a kid, tied it around his face
						to avoid inhaling smoke, and got to work.
					</p>
					<p>
						After leaving a voicemail for his mother Alison, Welles
						made his way to the 78th floor where he found a group of
						survivors. Carrying an injured woman on his back, Welles
						led this group down 17 flights of stairs to the 61st
						floor. Welles then returned to the 78th floor where he
						continued administering aid and marshaling others to
						safety.
					</p>
					<p>
						While most fled looking for safety, Welles was last seen
						turning back up the stairs of the South Tower, alongside
						FDNY personnel armed with a rescue tool to free trapped
						victims, before the building collapsed at 9:59AM.
					</p>
					<p>
						This heroic tale of Welles’ final hour finally reached
						his mother Alison and the rest of the Crowther family
						almost a year later. Ultimately 12 people reported they
						had been saved by a man who spoke with calm authority
						amidst the chaos, a man who was trained in first aid and
						fire safety, a man who fearlessly and repeatedly ran up
						the stairs of the South Tower, a man who donned a red
						bandanna. At least 12 people were saved on September
						11th, 2001 by Welles Remy Crowther.
					</p>
					<p>
						The Endstate team is honored to further Welles’ legacy
						by raising money for The Welles Remy Crowther Charitable
						Trust.
					</p>
				</div>
				<div className="crowther-mint-model">
					<div className="sketchfab-embed-wrapper">
						<iframe
							title="Drop 2: Statecraft"
							frameBorder="0"
							allowFullScreen
							// webkitallowfullscreen="true"
							// mozallowfullscreen="true"
							scrolling="no"
							allow="autoplay; fullscreen; xr-spatial-tracking"
							xr-spatial-tracking="true"
							execution-while-out-of-viewport="true"
							execution-while-not-rendered="true"
							web-share="true"
							src={sketchfabUrl}
						/>
					</div>
				</div>
			</div>
			<div className="crowther-long-description crowther-long-description-large">
				<h2 className="mb-4">Welles' Story</h2>
				<p>
					Welles Crowther was an equities trader and volunteer
					firefighter who gave his life to save at least 12 people
					during the 9/11 terrorist attacks in New York City.
				</p>
				<p>
					After graduating from Boston College in 1999, Welles began
					work on the 104th floor of the South Tower of the World
					Trade Center in NYC.
				</p>
				<p>
					On September 11, 2001 when the attacks began, Welles pulled
					out his red bandanna, which he had kept in his back pocket
					since he was a kid, tied it around his face to avoid
					inhaling smoke, and got to work.
				</p>
				<p>
					After leaving a voicemail for his mother Alison, Welles made
					his way to the 78th floor where he found a group of
					survivors. Carrying an injured woman on his back, Welles led
					this group down 17 flights of stairs to the 61st floor.
					Welles then returned to the 78th floor where he continued
					administering aid and marshaling others to safety.
				</p>
				<p>
					While most fled looking for safety, Welles was last seen
					turning back up the stairs of the South Tower, alongside
					FDNY personnel armed with a rescue tool to free trapped
					victims, before the building collapsed at 9:59AM.
				</p>
				<p>
					This heroic tale of Welles’ final hour finally reached his
					mother Alison and the rest of the Crowther family almost a
					year later. Ultimately 12 people reported they had been
					saved by a man who spoke with calm authority amidst the
					chaos, a man who was trained in first aid and fire safety, a
					man who fearlessly and repeatedly ran up the stairs of the
					South Tower, a man who donned a red bandanna. At least 12
					people were saved on September 11th, 2001 by Welles Remy
					Crowther.
				</p>
				<p>
					The Endstate team is honored to further Welles’ legacy by
					raising money for The Welles Remy Crowther Charitable Trust.
				</p>
			</div>
		</div>
	);
};

export default CrowtherMintDetails;
