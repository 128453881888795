import './App.scss';

import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Drop2Voucher from './pages/drop2/Drop2Voucher';
import Header from './components/header/Header';
import Drop2 from './pages/drop2/Drop2';
import Drop2Overview from './pages/drop2/Drop2Overview';
import Footer from './components/footer/Footer';
import Crowther from './pages/crowther/Crowther';
import DevontaOverview from './pages/devonta/DevontaOverview';
import Home from './pages/Home';
import Athletes from './pages/drops/athletes/Athletes';
import { RecoilRoot } from 'recoil';

import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import Cart from './pages/cart/Cart';
import BCNILDropDetails from './pages/drops/BCNILDropDetails';
import getBCNILPageContent from './drops/bcNILPageContent';

const { chains, provider } = configureChains([mainnet], [publicProvider()]);

const { connectors } = getDefaultWallets({
	appName: 'Endstate',
	chains,
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
});

const App: React.FC = () => {
	return (
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains} initialChain={mainnet}>
				<RecoilRoot>
					<BrowserRouter>
						<div className="parent">
							<Header />
							<section className="content">
								<Routes>
									<Route
										path="/certificate"
										element={<Drop2Voucher />}
									/>
									<Route
										path="/drop2"
										element={<Drop2Overview />}
									/>
									<Route
										path="/drop2/mint"
										element={<Drop2 />}
									/>
									<Route
										path="/"
										element={<Navigate to="/EndstateU" />}
									/>
									{/* <Route path="/" element={<Home />} /> */}
									<Route path="/cart" element={<Cart />} />
									<Route
										path="/EndstateU"
										element={<Athletes />}
									/>
									<Route
										path="/EndstateU/marne-sullivan"
										element={
											<BCNILDropDetails
												dropContent={getBCNILPageContent(
													'Marne Sullivan'
												)}
											/>
										}
									/>
									<Route
										path="/EndstateU/demarr-langford"
										element={
											<BCNILDropDetails
												dropContent={getBCNILPageContent(
													'DeMarr Langford'
												)}
											/>
										}
									/>
									<Route
										path="/EndstateU/cayla-barnes"
										element={
											<BCNILDropDetails
												dropContent={getBCNILPageContent(
													'Cayla Barnes'
												)}
											/>
										}
									/>
									<Route
										path="/EndstateU/christian-mahogany"
										element={
											<BCNILDropDetails
												dropContent={getBCNILPageContent(
													'Christian Mahogany'
												)}
											/>
										}
									/>
									<Route
										path="/EndstateU/zay-flowers"
										element={
											<BCNILDropDetails
												dropContent={getBCNILPageContent(
													'Zay Flowers'
												)}
											/>
										}
									/>
									<Route
										path="/crowther"
										element={<Crowther />}
									/>
									<Route
										path="/devontasmith"
										element={<DevontaOverview />}
									/>
									<Route
										path="/devonta"
										element={
											<Navigate to="/DeVontaSmith" />
										}
									/>
								</Routes>
							</section>
							<Footer />
						</div>
					</BrowserRouter>
				</RecoilRoot>
			</RainbowKitProvider>
		</WagmiConfig>
	);
};

export default App;
