import crowtherMp4 from '../assets/images/crowther/crowther_low_resolution.mp4';

const nftContent: {
	[key: string]: {
		id: number;
		nftArtUrl: string;
		sketchfabUrl: string;
	};
} = {
	crowther: {
		id: 0,
		nftArtUrl: crowtherMp4,
		sketchfabUrl:
			'https://sketchfab.com/models/7d94c358b2784185b7acb73ee2c188d9/embed',
	},
};

const getDescription = () => (
	<div className="crowther-mint-description">
		The Red Bandanna Sneaker is launching in collaboration with the Welles
		Remy Crowther Charitable Trust. 100% of the proceeds for this sneaker
		drop will be donated to the Trust to further Welles Crowther’s legacy of
		heroism and community service.
	</div>
);

const genericAttributes = [
	{ key: 'Release', value: 'Red Bandanna Sneaker' },
	{ key: 'Silhouette', value: 'Predawn Gen 2' },
	{ key: 'Colorway', value: 'Red Bandanna' },
	{ key: 'Designers', value: 'Stephanie Howard and Bennett Collen' },
	{ key: 'Collaborator', value: 'Welles Remy Crowther Charitable Trust' },
	{ key: 'Primary Color', value: undefined },
	{ key: 'Secondary Color', value: undefined },
	{ key: 'Tertiary Color', value: undefined },
	{ key: 'Made In', value: 'USA with imported materials' },
	{ key: 'Lace Color', value: undefined },
	{
		key: 'Environment',
		value: 'For Welles by Shane Leonard',
	},
	{ key: 'Motif', value: 'Bandanna' },
	{ key: 'Lace Location', value: 'Symmetrical' },
	{ key: 'Design Pattern', value: 'Classic Paneling' },
	{ key: 'Design Style', value: 'Knit' },
	{ key: 'Midsole Color', value: undefined },
	{ key: 'Outsole Color', value: undefined },
	{ key: 'Heel Pattern', value: 'Reflective' },

	{ key: 'Tongue Logo Color', value: undefined },
	{ key: 'Medial Logo Color', value: undefined },
	{ key: 'Upper', value: '95% Recycled Knit' },
	{ key: 'Sole', value: 'Polyurethane' },
];

const attributesByColorway: { [key: string]: any[] } = {
	crowther: [
		{ key: 'Primary Color', value: 'Red' },
		{ key: 'Secondary Color', value: 'Ivory' },
		{ key: 'Tertiary Color', value: 'Black' },
		{ key: 'Lace Color', value: 'Red & Ivory' },
		{ key: 'Midsole Color', value: 'Ivory' },
		{ key: 'Outsole Color', value: 'Black' },
		{ key: 'Tongue Logo Color', value: 'Red' },
		{ key: 'Medial Logo Color', value: 'Reflective' },
	],
};

const perks: { [key: string]: string[] } = {
	physical: [
		'• One pair of Red Bandanna Sneakers',
		'• Knit upper made from 95% recycled materials',
		'• Military-grade polyurethane midsole and solid rubber outsole',
		'• Made in the USA with imported materials',
	],
	digital: [
		'• 3D rendering of Red Bandanna Sneakers in a painted environment',
		'• Entry into The State Department channel within the Endstate Discord',
		'• Future metaverse integrations',
	],
	experiential: [
		'• Access to a tailgate hosted by Endstate at the 2022 Red Bandanna Game',
		'• Sneak peeks and input on future designs and collaborations',
		'• Authentication via NFC chip embedded in sneaker',
	],
};

const getCrowtherPageContent = () => {
	const attributes: any[] = structuredClone(genericAttributes);

	attributes.forEach((attr) => {
		if (attr.value === undefined) {
			attr.value = attributesByColorway['crowther'].find(
				(abc) => abc.key === attr.key
			)?.value;
		}
	});

	return {
		attributes,
		getDescription,
		perks,
		...nftContent['crowther'],
	};
};

export default getCrowtherPageContent;
