import './CrowtherMintButton.scss';

import { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import DonationModal from './DonationModal';
import useShopify from '../../../hooks/useShopify';
import {
	logEvent,
	setUserProperties,
} from '../../../services/AnalyticsService';
import { useSearchParams } from 'react-router-dom';

const CrowtherMintButton: React.FC<any> = ({
	className,
	fullWidth = false,
	errorBelow = false,
}: {
	colorway: string;
	className: string;
	buttonText: string | undefined;
	fullWidth: boolean;
	errorBelow: boolean;
	dontModifyParams: boolean;
}) => {
	const [searchParams] = useSearchParams({});
	const closed = searchParams.get('closed') === 'true';

	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(true);
	const [termsAndPolicyApproved, setTermsAndPolicyApproved] =
		useState<boolean>(false);

	const [showDonationModal, setShowDonationModal] = useState<boolean>(false);
	const [selectedVariant, setSelectedVariant] = useState<string | undefined>(
		undefined
	);
	const [selectedSize, setSelectedSize] = useState<string | undefined>(
		undefined
	);
	const [checkout, setCheckout] = useState<{
		url: string;
		id: number | string;
	}>();

	const [variants, setVariants] = useState<any[]>([]);

	const { prepareCheckout, getProductVariants } = useShopify();

	useEffect(() => {
		(async () => {
			if (variants.length === 0) {
				try {
					const productVariants = await getProductVariants(
						'7812819943644'
					);
					setVariants(productVariants);

					const totalQuantity = productVariants
						.map((variant: any) => variant.quantity)
						.reduce((prev: number, next: number) => prev + next);

					setUserProperties({ quantity_left: totalQuantity });
				} catch (e: any) {
					setError('Unable to get sizes. Refresh to try again.');
				}
				setLoading(false);
			}
		})();
	}, [getProductVariants, variants]);

	const getMintButton = useCallback(() => {
		return (
			<div
				className={`crowther-mint-button-component ${
					fullWidth ? 'crowther-mint-button-full' : ''
				}`}
			>
				<>
					{!errorBelow && error.length > 0 && (
						<p className="crowther-mint-error">{error}</p>
					)}
					{!closed ? (
						<div className="d-flex flex-column">
							<div className="d-flex flex-row-reverse mb-3">
								<Form.Check
									type="checkbox"
									label=""
									checked={termsAndPolicyApproved}
									onChange={(e) => {
										setTermsAndPolicyApproved(
											e.target.checked
										);
									}}
								/>
								<p
									style={{
										color: '#ffffff',
										textAlign: 'right',
										paddingRight: '12px',
									}}
								>
									I agree to the Endstate{' '}
									<a
										style={{ color: '#ffffff' }}
										href="https://www.endstate.io/terms"
										target="_blank"
										rel="noreferrer"
									>
										Terms
									</a>{' '}
									and{' '}
									<a
										style={{ color: '#ffffff' }}
										href="https://www.endstate.io/privacy"
										target="_blank"
										rel="noreferrer"
									>
										Privacy Policy
									</a>
								</p>
							</div>
							<div className="d-flex mb-3">
								<div>
									<Form.Select
										className="crowther-mint-size-selector"
										onChange={(e) => {
											setSelectedVariant(e.target.value);
											setSelectedSize(
												variants.find(
													(variant) =>
														variant.id ===
														e.target.value
												)?.name
											);
										}}
									>
										<option>SIZE</option>
										{variants.map((variant, i) => (
											<option
												key={i}
												disabled={
													variant.quantity === 0
												}
												value={variant.id}
											>
												{variant.name}
											</option>
										))}
									</Form.Select>
								</div>
								<Button
									className={`crowther-mint-button ${className}`}
									disabled={
										!selectedVariant ||
										!termsAndPolicyApproved
									}
									onClick={async () => {
										if (selectedVariant) {
											setLoading(true);
											logEvent('purchase_clicked', {
												size: selectedSize,
											});
											setCheckout(
												await prepareCheckout(
													selectedVariant
												)
											);
											setShowDonationModal(true);
											setLoading(false);
										}
									}}
								>
									{loading ? 'LOADING...' : `PURCHASE $250`}
								</Button>
							</div>
						</div>
					) : (
						<Button
							className={`crowther-mint-button ${className}`}
							disabled={true}
						>
							{loading ? 'LOADING...' : `COMING SEPTEMBER 7TH`}
						</Button>
					)}

					{errorBelow && error.length > 0 && (
						<p className="crowther-mint-error">{error}</p>
					)}
				</>
			</div>
		);
	}, [
		fullWidth,
		errorBelow,
		error,
		closed,
		termsAndPolicyApproved,
		variants,
		className,
		selectedVariant,
		loading,
		selectedSize,
		prepareCheckout,
	]);

	return (
		<>
			{getMintButton()}
			<DonationModal
				show={showDonationModal}
				checkout={checkout}
				size={selectedSize}
				handleClose={() => {
					setShowDonationModal(false);
				}}
			/>
		</>
	);
};

export default CrowtherMintButton;
