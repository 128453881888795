import './LinkConnectButton.scss';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export const LinkConnectButton = ({
	showAccount,
	centered = false,
}: {
	showAccount: boolean;
	centered?: boolean;
}) => {
	return (
		<ConnectButton.Custom>
			{({
				account,
				chain,
				openAccountModal,
				openChainModal,
				openConnectModal,
				authenticationStatus,
				mounted,
			}) => {
				// Note: If your app doesn't use authentication, you
				// can remove all 'authenticationStatus' checks
				const ready = mounted && authenticationStatus !== 'loading';
				const connected =
					ready &&
					account &&
					chain &&
					(!authenticationStatus ||
						authenticationStatus === 'authenticated');

				return (
					<div
						{...(!ready && {
							'aria-hidden': true,
							style: {
								opacity: 0,
								pointerEvents: 'none',
								userSelect: 'none',
							},
						})}
						className={`${
							centered ? 'd-flex justify-content-center' : ''
						}`}
					>
						{(() => {
							if (!connected) {
								return (
									<button
										className="link-connect-button"
										onClick={openConnectModal}
										type="button"
									>
										Connect a Wallet
									</button>
								);
							}

							if (chain.unsupported) {
								return (
									<button
										className="link-connect-button"
										onClick={openChainModal}
										type="button"
									>
										Wrong network
									</button>
								);
							}

							return (
								<div style={{ display: 'flex', gap: 12 }}>
									{/* <button
										onClick={openChainModal}
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
										type="button"
									>
										{chain.hasIcon && (
											<div
												style={{
													background:
														chain.iconBackground,
													width: 12,
													height: 12,
													borderRadius: 999,
													overflow: 'hidden',
													marginRight: 4,
												}}
											>
												{chain.iconUrl && (
													<img
														alt={
															chain.name ??
															'Chain icon'
														}
														src={chain.iconUrl}
														style={{
															width: 12,
															height: 12,
														}}
													/>
												)}
											</div>
										)}
										{chain.name}
									</button> */}

									<button
										className="link-connect-button"
										onClick={openAccountModal}
										type="button"
									>
										{showAccount ? (
											<>
												{account.displayName}
												{account.displayBalance
													? ` (${account.displayBalance})`
													: ''}
											</>
										) : (
											<>Change wallet</>
										)}
									</button>
								</div>
							);
						})()}
					</div>
				);
			}}
		</ConnectButton.Custom>
	);
};
