import './BCAthleteVerticalItem.scss';

import React, { useEffect, useRef, useState } from 'react';
import ICollabItemContent from '../../models/IDropData';

import { Collapse } from 'react-collapse';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import useOnScreen from '../../hooks/useOnScreen';

const ctaText = process.env.REACT_APP_NEW_HEIGHTS_MINTING_OPEN
	? 'BUY NOW'
	: 'SEE DETAILS';

const BCAthleteVerticalItem: React.FC<any> = ({
	itemContent,
	imageSrc,
	imageAlt,
}: {
	itemContent: ICollabItemContent;
	imageSrc: string;
	imageAlt: string;
}) => {
	const [isDetailsOpen, setIsDetailsOpen] = useState(isMobile);
	const [imageAnimationClass, setImageAnimationClass] = useState('');

	const ref = useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(ref);

	useEffect(() => {
		if (isMobile) {
			if (isVisible) {
				setImageAnimationClass('color-in');
			} else {
				setImageAnimationClass('color-out');
			}
		}
	}, [isVisible]);

	const navigate = useNavigate();
	return (
		<div
			ref={ref}
			className="bc-vertical-item"
			onMouseEnter={() => {
				setIsDetailsOpen(true);
				setImageAnimationClass('color-in');
			}}
			onMouseLeave={() => {
				setIsDetailsOpen(false);
				setImageAnimationClass('color-out');
			}}
			onClick={() => navigate(itemContent.link)}
		>
			<img
				className={`bc-vertical-img ${imageAnimationClass}`}
				src={imageSrc}
				alt={imageAlt}
			/>
			<div className="bc-vertical-collapse ">
				<Collapse isOpened={isDetailsOpen}>
					<div className="bc-vertical-details">
						<h2>{itemContent.name}</h2>
						<Button className="w-full mt-4">{ctaText}</Button>
					</div>
				</Collapse>
			</div>
		</div>
	);
};

export default BCAthleteVerticalItem;
