import './DonationModal.scss';

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Slider from 'react-input-slider';
import useShopify from '../../../hooks/useShopify';
import { logPurchaseEvent } from '../../../services/AnalyticsService';

const DonationModal: React.FC<any> = ({
	show,
	handleClose,
	checkout,
	size,
}) => {
	const [state, setState] = useState({ x: 0 });
	const [donationVariants, setDonationVariants] = useState<
		{ amount: string; id: number }[]
	>([]);

	const { proceedToPurchase, getProductVariants, addVariantToCheckout } =
		useShopify();

	useEffect(() => {
		(async () => {
			if (donationVariants.length === 0) {
				const variants = await getProductVariants('7823010922716');
				setDonationVariants(
					variants.map((variant: any) => ({
						amount: variant.name,
						id: variant.id,
					}))
				);
			}
		})();
	}, [getProductVariants, donationVariants]);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			dialogClassName="donation-modal"
		>
			<Modal.Header closeButton onHide={handleClose}>
				<Modal.Title>
					Support the Welles Remy Crowther Charitable Trust
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p className="crowther-slider-description">
						If you'd like to contribute more than the minimum $250
						to the Welles Remy Crowther Charitable Trust, scale your
						donation below.
						<br />
						<br />
						Thank you for your support.
					</p>
					<div className="crowther-slider-container mt-4">
						<p className="crowther-slider-value">
							<b>
								{state.x === 250 ? 'Minimum ' : ''}${state.x}
							</b>
						</p>
						<Slider
							styles={{
								track: {
									width: '100%',
									active: {
										backgroundColor: '#4e44ce',
									},
								},
							}}
							axis="x"
							x={state.x}
							xmin={0}
							xmax={5000}
							xstep={100}
							onChange={({ x }) =>
								setState((state) => ({ ...state, x }))
							}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					className="crowther-mint-button"
					onClick={async () => {
						const donationVariant = donationVariants.find(
							(variant) =>
								variant.amount === '$' + state.x.toString()
						);
						if (donationVariant) {
							await addVariantToCheckout(
								checkout.id,
								donationVariant.id
							);
						}
						logPurchaseEvent({
							donation_amount: state.x,
							size,
						});
						await proceedToPurchase(checkout);
						handleClose();
					}}
				>
					PROCEED TO CHECKOUT
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DonationModal;
